import React from "react";
import ReactDOM from "react-dom";

import CoreApp from "./core/app";
import WebApp from "./web/App";
import * as serviceWorker from "./serviceWorker";

const coreApp = new CoreApp();

ReactDOM.render(<WebApp coreApp={coreApp} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
