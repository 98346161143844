/* eslint no-console:[0] */
import { PAGE_SIZE } from "../../constants";

async function updateCloudReplicaRecursive({
  cloudReplica,
  appendOnlyLog,
  downloadCount = 0,
}) {
  const replicaActionsCount = await cloudReplica.getActionsCount();
  const lastReplicaAction = await cloudReplica.getLastAction();

  // always re-download the last known action from the appendOnlyLog.
  // - We use the last known action to verify there are no conflicts between
  //   the appendOnlyLog and the cloudReplica.
  // - We do not need to actually append the last known action to the cloudReplica.
  // - Keep in mind the appendOnlyLog uses 1-based indexing, not 0-based indexing.
  const from = replicaActionsCount || 1;
  const logActions = await appendOnlyLog.getActions({
    from,
    to: from + PAGE_SIZE,
  });

  if (replicaActionsCount > 0) {
    const lastKnownLogAction = logActions.shift();

    if (!lastKnownLogAction) {
      throw new Error(
        `Conflict: cloudReplica has ${replicaActionsCount} actions, but the appendOnlyLog row ${from} is empty`
      );
    }

    if (lastKnownLogAction !== lastReplicaAction) {
      throw new Error(`Conflict: Expected cloudReplica action #${from} to match appendOnlyLog action #${from}.
        Got:
        cloudReplica action: ${lastReplicaAction}
        appendOnlyLog action: ${lastKnownLogAction}
        `);
    }
  }

  if (logActions.length === 0) {
    return downloadCount;
  }

  const actions = logActions.map(JSON.parse);
  await cloudReplica.processActions(actions, { actionsAreRemote: true });
  return updateCloudReplicaRecursive({
    cloudReplica,
    appendOnlyLog,
    downloadCount: downloadCount + logActions.length,
  });
}

async function uploadLocalActionsRecursive({
  localDB,
  appendOnlyLog,
  uploadCount = 0,
}) {
  const actions = await localDB.getLocalActions({ from: 0, to: PAGE_SIZE });
  if (!actions || !actions.length) {
    return uploadCount; // done
  }
  await appendOnlyLog.appendActions(actions);
  await localDB.deleteLocalActions({ from: 0, to: actions.length });
  return uploadLocalActionsRecursive({
    localDB,
    appendOnlyLog,
    uploadCount: uploadCount + actions.length,
  });
}

async function hasConflicts({ cloudReplica, localDB }) {
  const cloudCount = await cloudReplica.getActionsCount();
  const localCount = await localDB.getActionsCount();
  if (cloudCount !== localCount) {
    console.warn(
      `Conflict. cloudReplica actions count: ${cloudCount} vs localDB actions count: ${localCount}`
    );
    return true;
  }

  const cloudLastAction = await cloudReplica.getLastAction();
  const localLastAction = await localDB.getLastAction();
  if (
    (cloudLastAction || localLastAction) && // skip comparison if both are falsy
    cloudLastAction !== localLastAction
  ) {
    console.warn(
      `Conflict. cloudReplica last action:\n${cloudLastAction}\nvs localDB last action:\n${localLastAction}`
    );
    return true;
  }

  return false;
}

// get all actions from `fromDB` and process them in `toDB`
async function copyLocalActionsRecursive({ fromDB, toDB, from }) {
  const lowerBound = from || 0;
  const upperBound = lowerBound + PAGE_SIZE;
  const localActions = await fromDB.getLocalActions({
    from: lowerBound,
    to: upperBound,
  });
  if (!localActions.length) {
    return Promise.resolve(); // done
  }
  await toDB.processActions(localActions);
  return copyLocalActionsRecursive({ fromDB, toDB, from: upperBound + 1 });
}

// SyncRecursive will sync a localDB with an appendOnlyLog, using a
// cloudReplicaDB as a helper.
//
// IMPORTANT:
// If a conflict is found between localDB and appendOnlyLog:
// - A **new** localDB will be generated by calling `cloneDB()`.
// - The new localDB will be synced with the appendOnlyLog.
//
// Returns the localDB that was synced (either `localDB` OR `cloneDB`).
export default async function syncRecursive({
  cloudReplica,
  localDB,
  appendOnlyLog,
  cloneDB,
}) {
  // update cloud replica
  try {
    const downloadCount = await updateCloudReplicaRecursive({
      cloudReplica,
      appendOnlyLog,
    });
    if (downloadCount > 0) {
      console.log(
        "Total appendOnlyLog actions downloaded to cloudReplica:",
        downloadCount
      );
    }
    console.log("cloudReplica is up to date.");
  } catch (err) {
    console.error(err);
    throw new Error("Failed to download actions from appendOnlyLog.");
  }

  // upload local actions
  try {
    const uploadCount = await uploadLocalActionsRecursive({
      localDB,
      appendOnlyLog,
    });
    if (uploadCount > 0) {
      console.log("Total localActions uploaded to appendOnlyLog:", uploadCount);
      return syncRecursive({
        cloudReplica,
        localDB,
        appendOnlyLog,
        cloneDB,
      });
    }
    console.log("All localActions have been uploaded.");
  } catch (err) {
    console.error(err);
    throw new Error("Failed to upload localActions to appendOnlyLog.");
  }

  // check conflicts
  if (await hasConflicts({ cloudReplica, localDB })) {
    const newDB = await cloneDB(cloudReplica);
    await copyLocalActionsRecursive({ fromDB: localDB, toDB: newDB });
    console.log(`New localDB ${newDB.name} was created. Sync succeeded.`);
    return newDB;
  }

  console.log("Sync succeeded.");
  return localDB;
}
